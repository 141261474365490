.enrollment {
  &__hero {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 45.556em;
    padding-bottom: 4.306em;
    &__content {
      display: flex;
      justify-content: space-between;
      &__description {
        width: 45em;
        &__text {
          margin-top: 1.5em;
        }
        &__help-text {
          margin-top: 2.333em;
          margin-bottom: .75em;
        }
      }
      &__center-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 9.2em;
        margin-top: 2.5em;
        margin-bottom: 3.681em;
        &__center-container {
          margin-top: 2.223em;
          &:first-child {
            margin-top: 0;
          }
          &:nth-child(4) {
            margin-top: 0;
          }
        }
        &__center {
          display: flex;
          text-decoration: none;
          width: max-content;
        }
      }
      &__container-visiting {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__visiting {
        width: 35.422em;
        height: 33.773em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: center;
        &-text-block {
          width: 23.743em;
          height: 9.137em;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          &__text {
            font-family: 'Zen Dots';
            font-size: 2.5rem;
            text-align: center;
            line-height: .88em;
          }
        }
      }
    }
  }
  &__right-now {
    display: flex;
    justify-content: center;
    padding-top: 1.181em;
    padding-bottom: 3.889em;
    &__content {
      display: flex;
      justify-content: space-between;
      &__image {
        width: 39.862em;
        & img {
          width: 100%;
        }
      }
    }
    &__description {
      width: 40.834em;
      padding-top: 5.07em;
      &__text {
        margin-top: .5em;
        margin-bottom: 1.5em;
      }
    }
  }
  &__available-courses {
    display: flex;
    justify-content: center;
    padding-top: 4.445em;
    padding-bottom: 5em;
    &__content {
      color: white;
    }
    &__title {
      margin-bottom: .8123em;
    }
    &__description-container {
      width: 45.8em;
      margin-top: 5.209em;
      margin-bottom: 2.084em;
    }

    &__schedule {
      background-color: var(--blue-900);
      width: max-content;
      text-align: center;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(6, 8em) 1em;
      grid-template-rows: max-content 2em 1fr 1fr 1fr 1fr;
      grid-template-areas: '. enrollment-schedule-title enrollment-schedule-title enrollment-schedule-title enrollment-schedule-title enrollment-schedule-title .'
                           'enrollment-schedule-top-decoration enrollment-schedule-groups-title enrollment-schedule-group-monday enrollment-schedule-group-tuesday enrollment-schedule-group-wednesday enrollment-schedule-group-thursday .'
                           'enrollment-schedule-group-morning-kids enrollment-schedule-kids-morning enrollment-schedule-kids-morning-monday enrollment-schedule-kids-morning-tuesday enrollment-schedule-kids-morning-wednesday enrollment-schedule-kids-morning-thursday .'
                           'enrollment-schedule-group-afternoon-kids enrollment-schedule-kids-afternoon enrollment-schedule-kids-afternoon-monday enrollment-schedule-kids-afternoon-tuesday enrollment-schedule-kids-afternoon-wednesday enrollment-schedule-kids-afternoon-thursday .'
                           'enrollment-schedule-group-morning-teens enrollment-schedule-teens-morning enrollment-schedule-teens-morning-monday enrollment-schedule-teens-morning-tuesday enrollment-schedule-teens-morning-wednesday enrollment-schedule-teens-morning-thursday .'
                           'enrollment-schedule-group-afternoon-teens enrollment-schedule-teens-afternoon enrollment-schedule-teens-afternoon-monday enrollment-schedule-teens-afternoon-tuesday enrollment-schedule-teens-afternoon-wednesday enrollment-schedule-teens-afternoon-thursday .';
      column-gap: 1em;
      padding: 2.5em 2.084em 2.153em 1.667em;
      border-radius: 10px;
      margin: auto;
      margin-top: 3.056em;

      &__day-time {
        display: none;
      }
      &__group-morning-kids,
      &__group-afternoon-kids,
      &__group-morning-teens,
      &__group-afternoon-teens {
        background-color: var(--turquoise-100);
        color: var(--turquoise-900);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em 0;
      }
      &__group-afternoon-teens {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &__title {
        grid-area: enrollment-schedule-title;
        margin-bottom: 1.3122em;
      }
      &__groups-title {
        grid-area: enrollment-schedule-groups-title;
      }
      &__group-monday {
        grid-area: enrollment-schedule-group-monday;
      }
      &__group-tuesday {
        grid-area: enrollment-schedule-group-tuesday;
      }
      &__group-wednesday {
        grid-area: enrollment-schedule-group-wednesday;
      }
      &__group-thursday {
        grid-area: enrollment-schedule-group-thursday;
      }
      &__group-morning-kids {
        grid-area: enrollment-schedule-group-morning-kids;
      }
      &__group-afternoon-kids {
        grid-area: enrollment-schedule-group-afternoon-kids;
      }
      &__group-morning-teens {
        grid-area: enrollment-schedule-group-morning-teens;
      }
      &__group-afternoon-teens {
        grid-area: enrollment-schedule-group-afternoon-teens;
      }
      &__kids-morning {
        grid-area: enrollment-schedule-kids-morning;
      }
      &__kids-afternoon {
        grid-area: enrollment-schedule-kids-afternoon;
      }
      &__teens-morning {
        grid-area: enrollment-schedule-teens-morning;
      }
      &__teens-afternoon {
        grid-area: enrollment-schedule-teens-afternoon;
      }
      &__kids-morning-monday {
        grid-area: enrollment-schedule-kids-morning-monday;
      }
      &__kids-morning-tuesday {
        grid-area: enrollment-schedule-kids-morning-tuesday;
      }
      &__kids-morning-wednesday {
        grid-area: enrollment-schedule-kids-morning-wednesday;
      }
      &__kids-morning-thursday {
        grid-area: enrollment-schedule-kids-morning-thursday;
      }
      &__kids-afternoon-monday {
        grid-area: enrollment-schedule-kids-afternoon-monday;
      }
      &__kids-afternoon-tuesday {
        grid-area: enrollment-schedule-kids-afternoon-tuesday;
      }
      &__kids-afternoon-wednesday {
        grid-area: enrollment-schedule-kids-afternoon-wednesday;
      }
      &__kids-afternoon-thursday {
        grid-area: enrollment-schedule-kids-afternoon-thursday;
      }
      &__teens-morning-monday {
        grid-area: enrollment-schedule-teens-morning-monday;
      }
      &__teens-morning-tuesday {
        grid-area: enrollment-schedule-teens-morning-tuesday;
      }
      &__teens-morning-wednesday {
        grid-area: enrollment-schedule-teens-morning-wednesday;
      }
      &__teens-morning-thursday {
        grid-area: enrollment-schedule-teens-morning-thursday;
      }
      &__teens-afternoon-monday {
        grid-area: enrollment-schedule-teens-afternoon-monday;
      }
      &__teens-afternoon-tuesday {
        grid-area: enrollment-schedule-teens-afternoon-tuesday;
      }
      &__teens-afternoon-wednesday {
        grid-area: enrollment-schedule-teens-afternoon-wednesday;
      }
      &__teens-afternoon-thursday {
        grid-area: enrollment-schedule-teens-afternoon-thursday;
      }
      &__top-decoration {
        grid-area: enrollment-schedule-top-decoration;
        background-color: var(--turquoise-100);
        width: 100%;
        height: 70%;
        align-self: flex-end;
        border-top-right-radius: 10px;;
        border-top-left-radius: 10px;;
      }
    }

    &__plans-container {
      display: grid;
      grid-template-areas: 'plans-page__individual-more-hours-plan plans-page__individual-more-hours-plan'
                           'plans-page__container__middle-line plans-page__container__middle-line'
                           'plans-page__christmas-plan plans-page__christmas-plan'
                           'plans-page__container__middle-line-2 plans-page__container__middle-line-2'
                           'plans-page__english-online-monthly plans-page__english-online-quarterly'
                           'plans-page__container__middle-line-3 plans-page__container__middle-line-3'
                           'plans-page__videogames-online-monthly plans-page__videogames-online-quarterly'
                           'plans-page__container__middle-line-4 plans-page__container__middle-line-4'
                           'plans-page__entrepreneurship-technology-english-monthly plans-page__entrepreneurship-technology-english-quarterly';
      // flex-wrap: wrap;
      justify-content: center;
      column-gap: 5em;
      row-gap: 5em;
      & .entrepreneurship-registration-plan {
        justify-self: flex-end;
        align-self: flex-end;
      }
      & .individual-plan,
      & .entrepreneurship-technology-english-monthly,
      & .english-online-monthly,
      & .videogames-online-monthly {
        justify-self: flex-end;
      }
    }
    &__middle-line-new-section {
      margin-bottom: 5em;
    }
    &__contact-us {
      margin-top: 5em;
      // padding-bottom: 19.028em;
      padding-bottom: 21em;
      &__content {
        display: flex;
        position: relative;
      }
      &__text-container {
        width: 29.584em;
      }
      &__image {
        width: 62.917em;
        position: absolute;
        right: -2.084em;
        & > img {
          width: 100%;
        }
      }
      &__subtitle {
        font-family: 'Zen Dots';
        font-weight: 400;
        margin-bottom: .5em;
      }
      &__text-description {
        width: 12.248em;
        margin-top: 1em;
        margin-bottom: 2.333em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .enrollment {
    &__hero {
      height: max-content;
      padding-top: 5.016em;
      &__content {
        flex-direction: column;
        &__description {
          width: 100%;
        }
        &__center-list {
          height: max-content;
          &__center-container:nth-child(4) {
            margin-top: inherit;
          }
        }
      }
    }

    &__right-now {
      &__content {
        flex-direction: column;
      }
    }

    &__available-courses {
      &__description-container {
        width: 100%;
      }

      &__schedule {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(17, max-content);
        grid-template-areas: 'enrollment-schedule-title enrollment-schedule-title'
                             'enrollment-schedule-group-morning-kids enrollment-schedule-group-morning-kids'
                             'enrollment-schedule-kids-morning enrollment-schedule-kids-morning'
                             'enrollment-schedule-kids-morning-monday enrollment-schedule-kids-morning-tuesday'
                             'enrollment-schedule-kids-morning-wednesday enrollment-schedule-kids-morning-thursday'
                             'enrollment-schedule-group-afternoon-kids enrollment-schedule-group-afternoon-kids'
                             'enrollment-schedule-kids-afternoon enrollment-schedule-kids-afternoon'
                             'enrollment-schedule-kids-afternoon-monday enrollment-schedule-kids-afternoon-tuesday'
                             'enrollment-schedule-kids-afternoon-wednesday enrollment-schedule-kids-afternoon-thursday'
                             'enrollment-schedule-group-morning-teens enrollment-schedule-group-morning-teens'
                             'enrollment-schedule-teens-morning enrollment-schedule-teens-morning'
                             'enrollment-schedule-teens-morning-monday enrollment-schedule-teens-morning-tuesday'
                             'enrollment-schedule-teens-morning-wednesday enrollment-schedule-teens-morning-thursday'
                             'enrollment-schedule-group-afternoon-teens enrollment-schedule-group-afternoon-teens'
                             'enrollment-schedule-teens-afternoon enrollment-schedule-teens-afternoon'
                             'enrollment-schedule-teens-afternoon-monday enrollment-schedule-teens-afternoon-tuesday'
                             'enrollment-schedule-teens-afternoon-wednesday enrollment-schedule-teens-afternoon-thursday';
        row-gap: 3em;

        &__title {
          margin-bottom: 0;
        }
        &__group-morning-kids,
        &__group-afternoon-kids,
        &__group-morning-teens,
        &__group-afternoon-teens {
          width: 30.512em;
          margin: 0 auto;
          padding-right: 7em;
          padding-left: 7em;
          border-radius: 10px;
        }
        &__day-time {
          display: block;
        }
        &__age-break,
        &__top-decoration,
        &__groups-title,
        &__group-monday,
        &__group-tuesday,
        &__group-wednesday,
        &__group-thursday {
          display: none;
        }
      }

      &__plans-container {
        grid-template-areas: 'plans-page__individual-more-hours-plan'
                             'plans-page__container__middle-line'
                             'plans-page__christmas-plan'
                             'plans-page__container__middle-line-2'
                             'plans-page__english-online-monthly'
                             'plans-page__english-online-quarterly'
                             'plans-page__container__middle-line-3'
                             'plans-page__videogames-online-monthly'
                             'plans-page__videogames-online-quarterly'
                             'plans-page__container__middle-line-4'
                             'plans-page__entrepreneurship-technology-english-monthly'
                             'plans-page__entrepreneurship-technology-english-quarterly';
        // column-gap: 0;
      }
      &__contact-us {
        // padding-bottom: 5.016em;
        padding-bottom: 0;
        &__content {
          flex-direction: column;
        }
        &__image {
          position: relative;
          right: 0;
          width: 100%;
          margin-top: 1.673em;
        }
      }
    }
  }
}