@import '../variables.scss';

@mixin button-style() {
  color: white;
  text-decoration: none;
  
  width: 5.834em;
  height: 3.334em;
  border: 0;
  border-radius: 55px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1024px) {
    width: 11.704em;
    height: 6.688em;
  }
}



.button-signup-signin {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;
  background-color: var(--purple);
  width: 5.834em;
  height: 3.334em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625em;
}

.button {
  @include button-style();
  background-color: var(--gray-200);

  &--first {
    @include button-style();
    background-color: var(--blue-900);

    &--small {
      @include button-style();
      background-color: var(--blue-900);
      width: 11.389em;
    }
    &--large {
      @include button-style();
      background-color: var(--blue-900);
      width: 22.778em;
    }
  }
  &--second {
    @include button-style();
    background-color: var(--pink-900);

    &--small {
      @include button-style();
      background-color: var(--pink-900);
      width: 11.389em;
    }
  }
  &--third {
    @include button-style();
    background-color: var(--turquoise-900);

    &--small {
      @include button-style();
      background-color: var(--turquoise-900);
      width: 11.389em;
    }
  }
}

@media screen and (max-width: 1024px) {
  .button {
    &--first {
      &--small {
        width: 22.85em;
      }
      &--large {
        width: 29.12em;
      }
    }
    &--second {
      &--small {
        width: 22.85em;
      }
    }
    &--third {
      &--small {
        width: 22.85em;
      }
    }
  }
}